@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #0000ff;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  color: #222;
  color: var(--mainBlack);
  background: #0000ff;
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid #0000ff;
  border: 3px solid var(--primaryColor);
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-warning {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  color: #222;
  color: var(--mainBlack);
  background: #0000ff;
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid #0000ff;
  border: 3px solid var(--primaryColor);
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover{
  text-decoration: none;
  background: transparent!important;
  color: #fff!important;
}
.btn-warning:hover{
  text-decoration: none;
  background: transparent!important;
  color: yellow!important;
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.roomerror{
  margin-top: 100px;
}
.roomerror:hover .error{
  background-color: #2C3E50;
  color: #ffffff!important;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}

/* end of globals */
/* Navbar */
.nav-link{
  padding: 8px 15px;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 18px;
  text-align: center;
  border: 2px solid transparent;
  transition: .5s linear;
  font-weight: 800;
  -webkit-transition: .5s linear;
  -moz-transition: .5s linear;
  -ms-transition: .5s linear;
  -o-transition: .5s linear;
}
.bg-transparent.scrolled {
  background-color: black!important;
  transition:500ms ease;
}

.navbar-brand{
  font-size: 32px!important;
  color: #ffffff!important;
}
.nav-link:hover{
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
}
.nav-link.active_class{
color: #ffffff;
border: 2px solid #ffffff;
font-weight: 800;
}

.form-control{
border: 2px solid #000;
}
.form-control:focus,
.form-control.active {
  border: 2px solid #000000!important;
}
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
box-shadow: inset 0 -1px 0 #ddd;
}


/* end of navbar */
/* Hero */
.defaultHero,
.roomsHero {
  min-height: calc(100vh - 0px);
  background: url(/static/media/defaultBcg.4072dfe3.jpg) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.roomsHero {
  background-image: url(/static/media/room-2.11343adf.jpg);
  min-height: calc(100vh - 0px);
}
.singleRoom {
  background-image: url(/static/media/room-3.9a1103b5.jpeg);
  min-height: calc(100vh - 0px);
}
.roomsError{
  background-image: url(/static/media/error.6edb36f3.jpg);
  min-height: calc(100vh - 0px);
}
.lost{
  font-size: 44px;
  color: yellow;
  text-align: center;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  position: absolute!important;
  background: rgba(0, 0, 0, 0.5)!important;
  color: #fff;
  color: var(--mainWhite);
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.banner h1 {
  font-size: 44px!important;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: #0000ff;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 576px) {
  .banner {
    padding: 2rem 3rem!important
  }
  .banner h1 {
    font-size: 32px!important;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: #0000ff;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 3rem 0;
}
.services {
  background: #EBF5FB;
  text-align: center;
}

.service span {
  display: inline-block;
  color: #0000ff;
  color: var(--primaryColor);
  font-size: 80px;
  margin-bottom: 1.5rem;
  transition: 1s linear;
  -webkit-transition: 1s linear;
  -moz-transition: 1s linear;
  -ms-transition: 1s linear;
  -o-transition: 1s linear;
}
.services span:hover{
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}
.services h6 {
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  font-weight: 1000;
  text-transform: capitalize;
}
.services p {
  width: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}

/* end pf featured rooms */
/* room */
.room {
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  position: relative;
}
.room:hover {
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.5)!important;
}

.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.room-link {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s linear;
}
.room:hover {
  background: rgba(0, 0, 0, 0.8);
}
.room:hover img {
  opacity: 0.3;
}
.room:hover .price-top {
  opacity: 0;
}
.room:hover .room-link {
  opacity: 1;
  transform: translate(-90%, -200%) scale(1);
  -webkit-transform: translate(-90%, -200%) scale(1);
  -moz-transform: translate(-90%, -200%) scale(1);
  -ms-transform: translate(-90%, -200%) scale(1);
  -o-transform: translate(-90%, -200%) scale(1);
}
.room-info {
  background: #F2F3F4;
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0;
}
/* end of room  */
/* single room*/

.single-room {
  padding: 5rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}
.desc,
.info {
  margin: 1rem 0;
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single room*/
/* roomlist */
.roomslist {
  padding: 5rem 0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomlist */
/*  rooms fitler*/
/* .filter-container {
  padding: 5rem 0;
}

.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
} */

.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

/* end of rooms fitler*/

/* aboutus */

.aboutus,.contact{
  margin-top: 100px!important;
}
.about_company,.team{
  padding: 60px 30px;
}
.about_info{
  letter-spacing: 40;
}
.carousel-inner{
  min-height: 400px!important;
  max-height: 400px!important;
}
.connect{
  font-size: 44px!important;
  padding: 5px 8px;
}
/* end of about us */
*{

  font-family: 'Nunito', sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, #0000ff);
    border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#D7DBDD, #0000ff);
}

@media (max-width: 576px) {
  ::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, #0000ff);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#D7DBDD, #0000ff);
}
}
